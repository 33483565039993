//
//
//
//

export default {
  data() {
    return {};
  },
  created() {
    this.$router
      .replace({ path: "/StuL", query: { clickEnent: "通讯录" } })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {},
};
